// 
// Variables
// 

// Override Bootstrap typography variables

$font-family-base: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-family: "Saira Extra Condensed", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-weight: 700;

$headings-color: $gray-800;

$h1-font-size: 6rem;
$h2-font-size: 3.5rem;
$h3-font-size: 2rem;
