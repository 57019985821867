// 
// Colors
// 

// Pre-state grayscale colors used in other variables

$gray-800: #343a40;

// Override Bootstrap default state colors

$primary: #009141;
