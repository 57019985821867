// 
// Spacing
// 

// Spacing variable to set the sidebar base width

$sidebar-base-width: 17rem;

// Spacing variable to add padding to the top of the body element that matches the height of the navbar

$navbar-base-height: 3.375rem;
